// @flow strict
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Content from '../components/content'
import Paragraph from '../components/paragraph'
import Articles from '../components/articles'
import ImageContainer from '../components/imageContainer'
import Row from '../components/row'
import type { MarkdownResult } from '../types'

const ContentBox = styled.div`
  border-radius: 4px;
  box-shadow: 5px 5px 15px 3px rgba(100, 100, 100, 0.5);
  padding: 1rem;
  color: black;
  border: 1px solid black;
  border-bottom 15px solid #04a47b;
  width: 90%;

  @media (min-width: 700px) {
    max-width: 70%;
    border: 1px solid black;
    border-right: 15px solid #04a47b;
  }
`

const MainContent = styled(Content)`
  flex-flow: wrap;
  margin-top: 2rem;
  align-items: flex-start;
  overflow: auto;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
`

const LinkedIn = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0px;
  right: 3px;
  transform: scale(1);
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`

const ExternalLink = styled.a`
  color: #0077b5;
`

const RelativeImage = styled(ImageContainer)`
  position: relative;
`

const linkedin =
  'https://www.linkedin.com/in/seyi-sowemimo-san-1b305a41/?originalSubdomain=ng'

type Data = MarkdownResult & {
  landingPageImg?: {
    childImageSharp: {
      fluid: string,
    },
  },
  site?: {
    siteMetadata: {
      title: string,
      description: string,
    },
  },
}

type Props = {
  data: Data,
  location: {
    pathname: string,
  },
}

const IndexPage = ({ data, location }: Props) => (
  <Layout location={location}>
    <SEO title="Home" keywords={[`law`, `react`, `legal`, `nigeria`, `SAN`]} />
    <MainContent>
      <Row>
        <RelativeImage>
          {data.landingPageImg && (
            <Img fluid={data.landingPageImg.childImageSharp.fluid} />
          )}
          <ExternalLink href={linkedin} target="_blank">
            <LinkedIn icon={faLinkedin} size="3x" />
          </ExternalLink>
        </RelativeImage>
        <ContentBox>
          <Paragraph margin="0 0 1rem">
            <strong>Seyi Sowemimo & Co. have a clear philosophy:</strong>
            <br />
            Serving client requirements with experience and diligence, providing
            <em> practical</em> and <em>creative</em> solutions to meet
            individual and business needs.
          </Paragraph>
          <Paragraph>
            The firm offers a wide range of legal services, comprising of
            litigation, arbitration, mediation and solicitor work.
            <br /> <br /> Over the years we have acquired wide experience and
            expertise in commercial and corporate work. Our services include
            corporate governance processes and judicial control of
            administrative bodies.
          </Paragraph>
        </ContentBox>
      </Row>
      <Articles markdown={data.allMarkdownRemark.edges} />
    </MainContent>
  </Layout>
)

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 4
      sort: { fields: [frontmatter___date], order: ASC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          excerpt
          html
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    landingPageImg: file(
      relativePath: { eq: "images/seyi_sowemimo_suit.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
