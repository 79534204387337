// @flow strict
import React from 'react'
import styled from 'styled-components'
import type { Edge } from '../types'
import StyledLink from './link'

const Card = styled.article`
  width: 20rem;
  height: 18rem;
  box-shadow: 0 1px 3px 0 rgba(100, 100, 100, 0.4);
  border-radius: 4px;
  margin: 1rem;
  background-color: whitesmoke;
  border: 0.5px solid black;
  > * {
    padding: 1rem;
  }
`

const Title = styled.h2`
  margin-bottom: 0;
  text-align: center;
  width: 100%;
  overflow: hidden;
  whitespace: nowrap;
  text-overflow: ellipsis;
`

const TitleContainer = styled.header`
  width: 100%;
  height: 4rem;
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  border-bottom 1px solid rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ArticlesContainer = styled.section`
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 2rem 0;
`

const ArticleTitle = styled.h3`
  border-bottom: 1px solid black;
  height: 5rem;
  overflow: hidden;
`

const DatePublished = styled.h4`
  margin-bottom: 0;
  padding: 0.5rem 1rem;
`

type Props = {
  markdown: Array<Edge>,
}

const Articles = ({ markdown }: Props) => (
  <ArticlesContainer>
    <>
      <TitleContainer>
        <Title>Recent Articles</Title>
      </TitleContainer>
      {markdown.map(({ node }: Edge) => (
        <StyledLink key={node.id} to={node.fields.slug}>
          <Card>
            <>
              <ArticleTitle>{node.frontmatter.title}</ArticleTitle>
              <DatePublished>{node.frontmatter.date}</DatePublished>
            </>
            <p>{node.excerpt}</p>
          </Card>
        </StyledLink>
      ))}
    </>
  </ArticlesContainer>
)

export default Articles
