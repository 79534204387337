import styled from 'styled-components'

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (min-width: 700px) {
    flex-direction: row;
    align-items: stretch;
    justify-content: space-around;
  }
`
export default Row
